/* eslint-disable @typescript-eslint/default-param-last */
import axios from 'axios'
import { Dispatch, SetStateAction } from 'react'
import { IndustryCodeEnum } from './IndustryCodeEnum'

export interface handleSubscribeToNewsletterProps {
  setIsSubscribing: Dispatch<SetStateAction<boolean>>
  setDisplaySuccessPrompt: (display: boolean) => Dispatch<SetStateAction<boolean>>
  handleError: (e: Error) => void
  email?: string
  customerFirstName?: string
  customerLastName?: string
  customerIndustry?: Array<string> | null | undefined
  isLoggedIn?: boolean
  additionalProperties?: Record<string, boolean>
}

export const handleSubscribeToNewsletter = async (
  setIsSubscribing: Dispatch<SetStateAction<boolean>> = () => {},
  setDisplaySuccessPrompt: Dispatch<SetStateAction<boolean>> = () => {},
  handleError: (e: Error) => void,
  email?: string | undefined,
  customerFirstName?: string,
  customerLastName?: string,
  customerIndustry?: Array<string> | null | undefined,
  isLoggedIn?: boolean,
  additionalProperties?: Record<string, boolean>,
) => {
  setIsSubscribing(true)
  const body = {
    type: 'profile',
    attributes: {
      email,
      first_name: customerFirstName ?? '',
      last_name: customerLastName ?? '',
      properties: {
        ...additionalProperties,
      },
    },
  }

  const result = await axios
    .request({
      method: 'POST',
      url: 'api/subscribeEmailToNewsletter',
      data: body ?? '',
    })
    .catch((e: Error) => {
      handleError(e)
    })
  if (result) {
    const profileId = result.data?.id

    let subscriptionBody = {}
    if (!isLoggedIn) {
      subscriptionBody = {
        type: 'profile-subscription-bulk-create-job',
        attributes: {
          profiles: {
            data: [
              {
                type: 'profile',
                id: profileId,
                attributes: {
                  email,
                  subscriptions: {
                    email: {
                      marketing: {
                        consent: 'SUBSCRIBED',
                      },
                    },
                  },
                },
              },
            ],
          },
          historical_import: false,
        },
        relationships: {
          list: {
            data: {
              type: 'list',
            },
          },
        },
      }
      const subcriptionResult = await axios
        .request({
          method: 'POST',
          url: 'api/addEmailToNewsletterList',
          data: subscriptionBody ?? '',
        })
        .catch((e: Error) => {
          handleError(e)
        })
      if (subcriptionResult) {
        setDisplaySuccessPrompt(true)
      }
    } else {
      customerIndustry?.map(async (industry) => {
        let persona
        switch (industry) {
          case IndustryCodeEnum.bar_restaurant.toLocaleString():
            persona = process.env.NEXT_PUBLIC_KLAVIYO_BAR_RESTAURANT_ID ?? ''
            break
          case IndustryCodeEnum.cafe_bakery.toLocaleString():
            persona = process.env.NEXT_PUBLIC_KLAVIYO_CAFE_BAKERY_ID ?? ''
            break
          case IndustryCodeEnum.designer.toLocaleString():
            persona = process.env.NEXT_PUBLIC_KLAVIYO_DESIGNER_ID
            break
          case IndustryCodeEnum.hotel.toLocaleString():
            persona = process.env.NEXT_PUBLIC_KLAVIYO_HOTEL_ID
            break
          case IndustryCodeEnum.personal.toLocaleString():
            persona = process.env.NEXT_PUBLIC_KLAVIYO_PERSONAL_USE_ID
            break
          case IndustryCodeEnum.retail.toLocaleString():
            persona = process.env.NEXT_PUBLIC_KLAVIYO_RETAIL_ID
            break
          case 'friends_of_templi':
            persona = process.env.NEXT_PUBLIC_KLAVIYO_FRIENDS_OF_TEMPLI_ID
            break
          default:
            persona = process.env.NEXT_PUBLIC_KLAVIYO_NEWSLETTER_ID
            break
        }

        subscriptionBody = {
          type: 'profile-subscription-bulk-create-job',
          attributes: {
            profiles: {
              data: [
                {
                  type: 'profile',
                  id: profileId,
                  attributes: {
                    email,
                    subscriptions: {
                      email: {
                        marketing: {
                          consent: 'SUBSCRIBED',
                        },
                      },
                    },
                  },
                },
              ],
            },
            historical_import: false,
          },
          relationships: {
            list: {
              data: {
                type: 'list',
                id: persona,
              },
            },
          },
        }
        const subcriptionResult = await axios
          .request({
            method: 'POST',
            url: 'api/addEmailToNewsletterList',
            data: subscriptionBody ?? '',
          })
          .catch((e: Error) => {
            handleError(e)
          })
        if (subcriptionResult) {
          setDisplaySuccessPrompt(true)
        }
      })
    }
  }
  setIsSubscribing(false)
}

export const handleGetKlaviyoProfileDetails = async (
  handleError: (e: Error) => void,
  email?: string | undefined,
) => {
  const result = await axios
    .request({
      method: 'POST',
      url: `api/getKlaviyoProfileDetails?email=${email}`,
    })
    .catch((e: Error) => {
      handleError(e)
    })
  return result
}

export const handleUnsubscribeProfileFromList = async (
  handleError: (e: Error) => void,
  email: string,
  industry?: string,
) => {
  let listID
  switch (industry) {
    case IndustryCodeEnum.bar_restaurant.toLocaleString():
      listID = process.env.NEXT_PUBLIC_KLAVIYO_BAR_RESTAURANT_ID ?? ''
      break
    case IndustryCodeEnum.cafe_bakery.toLocaleString():
      listID = process.env.NEXT_PUBLIC_KLAVIYO_CAFE_BAKERY_ID ?? ''
      break
    case IndustryCodeEnum.designer.toLocaleString():
      listID = process.env.NEXT_PUBLIC_KLAVIYO_DESIGNER_ID
      break
    case IndustryCodeEnum.hotel.toLocaleString():
      listID = process.env.NEXT_PUBLIC_KLAVIYO_HOTEL_ID
      break
    case IndustryCodeEnum.personal.toLocaleString():
      listID = process.env.NEXT_PUBLIC_KLAVIYO_PERSONAL_USE_ID
      break
    case IndustryCodeEnum.retail.toLocaleString():
      listID = process.env.NEXT_PUBLIC_KLAVIYO_RETAIL_ID
      break
    case 'friends_of_templi':
      listID = process.env.NEXT_PUBLIC_KLAVIYO_FRIENDS_OF_TEMPLI_ID
      break
    default:
      listID = process.env.NEXT_PUBLIC_KLAVIYO_NEWSLETTER_ID
      break
  }

  const body = {
    type: 'profile-subscription-bulk-delete-job',
    attributes: {
      profiles: {
        data: [
          {
            type: 'profile',
            attributes: {
              email,
            },
          },
        ],
      },
    },
    relationships: {
      list: {
        data: {
          type: 'list',
          id: listID,
        },
      },
    },
  }

  const result = await axios
    .request({
      method: 'POST',
      url: 'api/unsubcribeEmailFromNewsletterList',
      data: body ?? '',
    })
    .catch((e: Error) => {
      handleError(e)
    })
  return result
}

export const handleAddEmailToNewsletterList = async (
  setIsSubscribing: Dispatch<SetStateAction<boolean>> = () => {},
  setDisplaySuccessPrompt: Dispatch<SetStateAction<boolean>> = () => {},
  handleError: (e: Error) => void,
  email?: string | undefined,
  profileId?: string | undefined,
  customerIndustry?: Array<string> | null | undefined,
  // eslint-disable-next-line @typescript-eslint/require-await
) => {
  setIsSubscribing(true)
  let subcriptionResult
  customerIndustry?.map(async (industry) => {
    let persona
    switch (industry) {
      case IndustryCodeEnum.bar_restaurant.toLocaleString():
        persona = process.env.NEXT_PUBLIC_KLAVIYO_BAR_RESTAURANT_ID ?? ''
        break
      case IndustryCodeEnum.cafe_bakery.toLocaleString():
        persona = process.env.NEXT_PUBLIC_KLAVIYO_CAFE_BAKERY_ID ?? ''
        break
      case IndustryCodeEnum.designer.toLocaleString():
        persona = process.env.NEXT_PUBLIC_KLAVIYO_DESIGNER_ID
        break
      case IndustryCodeEnum.hotel.toLocaleString():
        persona = process.env.NEXT_PUBLIC_KLAVIYO_HOTEL_ID
        break
      case IndustryCodeEnum.personal.toLocaleString():
        persona = process.env.NEXT_PUBLIC_KLAVIYO_PERSONAL_USE_ID
        break
      case IndustryCodeEnum.retail.toLocaleString():
        persona = process.env.NEXT_PUBLIC_KLAVIYO_RETAIL_ID
        break
      case 'friends_of_templi':
        persona = process.env.NEXT_PUBLIC_KLAVIYO_FRIENDS_OF_TEMPLI_ID
        break
      default:
        persona = process.env.NEXT_PUBLIC_KLAVIYO_NEWSLETTER_ID
        break
    }

    const subscriptionBody = {
      type: 'profile-subscription-bulk-create-job',
      attributes: {
        profiles: {
          data: [
            {
              type: 'profile',
              id: profileId,
              attributes: {
                email,
                subscriptions: {
                  email: {
                    marketing: {
                      consent: 'SUBSCRIBED',
                    },
                  },
                },
              },
            },
          ],
        },
        historical_import: false,
      },
      relationships: {
        list: {
          data: {
            type: 'list',
            id: persona,
          },
        },
      },
    }

    subcriptionResult = await axios
      .request({
        method: 'POST',
        url: 'api/addEmailToNewsletterList',
        data: subscriptionBody ?? '',
      })
      .catch((e: Error) => {
        handleError(e)
      })
  })

  setIsSubscribing(false)
  if (subcriptionResult) {
    setDisplaySuccessPrompt(true)
  }
  return subcriptionResult
}
