import React from 'react'

export type DrawerParams = {
  redirectAfterSignIn?: string
}

export type GlobalContext = {
  promoOpen: boolean
  closePromo: () => void
  drawer: {
    id: null | false | 'nav' | 'auth' | 'cart' | 'account' | 'added_to_cart'
    params?: DrawerParams | undefined
  }
  setDrawer: (value: {
    id: null | false | 'nav' | 'auth' | 'cart' | 'account' | 'added_to_cart'
    params?: DrawerParams | undefined
  }) => void
  cartMergeInProgress: boolean
  setCartMergeInProgress: (value: boolean) => void
  isCcFormValid: boolean
  setIsCcFormValid: (value: boolean) => void
  isUsingCcPayment: boolean
  setIsUsingCcPayment: (value: boolean) => void
  isUsingPayPalPayment: boolean
  setIsUsingPayPalPayment: (value: boolean) => void
  eventCalledFor: Record<'beginCheckout', string[]>
  addEventCalledFor: (event: 'beginCheckout', cartId: string) => void
  isCompanyAccount: boolean
  isSubUser: boolean
  subUserCan: (permissionCode: string) => boolean | undefined
  setRedirectAfterSignIn: (string) => void
}

export const globalContext = React.createContext(undefined as unknown as GlobalContext)
globalContext.displayName = 'globalContext'
