import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'

export const StandardButton = styled(LoadingButton)({
  backgroundColor: '#2196F3',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#0069d9',
  },
})

export const StandardPrimaryButton = styled(LoadingButton)({
  backgroundColor: '#680BC5',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#53089D',
  },
})

export const SecondaryHeaderButton = styled(LoadingButton)({
  backgroundColor: '#F2FF5B33',
  color: '#000000',
  '&:hover': {
    backgroundColor: '#F2FF5B80',
  },
})

export const HighlightButton = styled(LoadingButton)({
  backgroundColor: '#FFD630',
  color: '#000000',
  textTransform: 'uppercase',
  fontWeight: 500,
  fontSize: '13px',
  padding: '4px 10px',
  '&:disabled': {
    backgroundColor: '#D1D3D4',
  },
  '&:hover': {
    backgroundColor: '#DCAE12',
  },
})

export const ApproveButton = styled(LoadingButton)({
  backgroundColor: '#2e7d32',
  color: '#FFFFFF',
  textTransform: 'uppercase',
  fontWeight: 500,
  fontSize: '13px',
  padding: '4px 10px',
  '&:hover': {
    backgroundColor: '#1b5e20',
  },
})

export const DeclineButton = styled(LoadingButton)({
  backgroundColor: '#D32F2F',
  color: '#FFFFFF',
  textTransform: 'uppercase',
  fontWeight: 500,
  fontSize: '13px',
  padding: '4px 10px',
  '&:hover': {
    backgroundColor: '#CC3333',
  },
})

export const SecondaryButton = styled(LoadingButton)({
  fontSize: 16,
  fontWeight: 500,
  padding: '8px 16px',
  lineHeight: '24px',
  backgroundColor: '#FFFFFF',
  color: '#2196F3',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2)',
  borderRadius: '2px',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.14)',
  },
})

export const TertiaryButton = styled(LoadingButton)({
  fontWeight: 500,
  fontSize: '13px',
  padding: '3px 9px',
  backgroundColor: '#F2F2F2',
  color: '#000000',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2)',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.14)',
  },
})

export const WhiteButton = styled(LoadingButton)({
  fontWeight: 500,
  fontSize: '13px',
  padding: '3px 9px',
  borderColor: '#000000',
  backgroundColor: '#FFFFFF',
  color: '#000000',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2)',
  '&:hover': {
    backgroundColor: '#F2F2F2',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.14)',
    borderColor: '#000000',
  },
})

export const BlackButton = styled(LoadingButton)({
  fontWeight: 500,
  fontSize: '13px',
  padding: '3px 9px',
  borderColor: '#000000',
  backgroundColor: '#000000',
  color: '#FFFFFF',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2)',
  '&:hover': {
    backgroundColor: '#2F2F2F',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.14)',
    borderColor: '#2F2F2F',
  },
  '.MuiLoadingButton-loadingIndicator': {
    color: '#FFFFFF',
  },
})
